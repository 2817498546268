import React, { FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { capitalizeFirstLetter } from 'helpers/other';
import { EnumReceiptStatus, EnumReceiptType, IDuplicate, IReceipt } from 'redux/receipt/types';

import CheckBox from '../CheckBox';
import { Loader } from 'components/Other/Loader';
import CapturedData from '../../blocks/CapturedData';
import DuplicateInfo from 'components/DuplicateInfo';

import {
    ActionIcon,
    CheckBoxWrapper,
    CompanyName,
    DotsText,
    LoaderContainer,
    Status,
    TableContainer,
    TableWrapper,
    Td1Icons,
    Tr
} from './styles';

import flagIcon from 'assets/images/icons/flag.png';
import columnsIcon from 'assets/images/icons/columns.png';
import documentIcon from 'assets/images/icons/document.png';
import flagActive from 'assets/images/icons/flagActive.png';
import emailIcon from 'assets/images/icons/email.png';
import { ButtonGreen, ButtonReject } from 'pages/EditReceipt/components/Actions/styles';

interface Props {
    popUp: any;
    data: IReceipt[];
    loading: boolean;
    capturedConfig: any;
    selected: IReceipt[];
    receiptType: EnumReceiptType | undefined;
    duplicateInfo: null | IDuplicate[];
    onSelectAll: () => void;
    toEdit: (item: IReceipt) => void;
    onSelect: (item: IReceipt) => void;
    openPopUp: (key: string) => () => void;
    toogleCaptureData: (k: string) => void;
    getDuplicateInfo: (r: IReceipt) => any;
    closePopUp: (key: string) => () => void;
    tooglePopUp: (key: string) => () => void;
    getReceiptEmailInfo: (r: IReceipt) => any;
    openFullPicture: (receipt: IReceipt) => void;
    changeFlag: (receipt: IReceipt, flag: boolean) => any;
    onDelete: (receipt: IReceipt) => void;
    onSendAgain: (receipt: IReceipt) => void;
}

const Table: FC<Props> = ({
    data,
    popUp,
    loading,
    selected,
    duplicateInfo,
    capturedConfig,
    toEdit,
    onSelect,
    openPopUp,
    closePopUp,
    changeFlag,
    onSelectAll,
    tooglePopUp,
    openFullPicture,
    getDuplicateInfo,
    toogleCaptureData,
    getReceiptEmailInfo,
    onDelete,
    onSendAgain
}) => {
    const notProccess = data.filter(
        (item) =>
            item.status !== EnumReceiptStatus.Processing &&
            item.status !== EnumReceiptStatus.Converting
    );

    return (
        <TableWrapper>
            <TableContainer>
                <thead>
                    <tr>
                        <th className="receipt_col1">
                            <Td1Icons>
                                <CheckBoxWrapper>
                                    <CheckBox
                                        active={notProccess.length === selected.length}
                                        onClick={onSelectAll}
                                    />
                                </CheckBoxWrapper>
                                <ActionIcon
                                    src={columnsIcon}
                                    alt="columns"
                                    onClick={tooglePopUp('capturedData')}
                                />
                                {popUp.capturedData && (
                                    <CapturedData
                                        capturedConfig={capturedConfig}
                                        onClose={closePopUp('capturedData')}
                                        toogleCaptureData={toogleCaptureData}
                                    />
                                )}
                            </Td1Icons>
                        </th>

                        <th className="receipt_col_buttons"></th>
                        <th className="receipt_col2">User</th>
                        <th className="receipt_col3">Received at</th>
                        <th className="receipt_col3">Company</th>
                        <th className="receipt_col4">Type</th>
                        <th className="receipt_col5">Company</th>
                        {/* <th className="receipt_col6">Category</th> */}
                        <th className="receipt_col7">Status</th>
                        {Object.keys(capturedConfig).map((k) => {
                            // @ts-ignore
                            if (capturedConfig[k].visible) {
                                return (
                                    <th className="receipt_col" key={k}>
                                        {capturedConfig[k].label}
                                    </th>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={7}>
                                <LoaderContainer>
                                    <Loader />
                                </LoaderContainer>
                            </td>
                        </tr>
                    ) : (
                        data.map((item, index) => {
                            // const category = item.category ? item.category : 'Unknown';
                            let stakeholderVal: string | undefined = undefined;

                            if (item.content || item.supplierCustomerName) {
                                if (item.receipt_type === 'receipt') {
                                    stakeholderVal =
                                        item.content?.supplier_name || item.supplierCustomerName;
                                } else {
                                    stakeholderVal =
                                        item.content?.customer_name || item.supplierCustomerName;
                                }
                            }

                            if (!stakeholderVal) {
                                stakeholderVal = item.stakeholder?.name
                                    ? item.stakeholder.name
                                    : 'Unknown';
                            }

                            const canBeRestarted = item.can_be_restarted;
                            const navToEdit = (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation();

                                if (canBeRestarted) {
                                    return;
                                }

                                switch (item.status) {
                                    case EnumReceiptStatus.Converting:
                                    case EnumReceiptStatus.Processing:
                                    case EnumReceiptStatus.Saving:
                                        return;
                                }

                                toEdit(item);
                            };
                            const isChecked = selected.find(
                                (e) => e.receipt_id === item.receipt_id
                            );

                            const chnageFlagHandle = () => {
                                if (
                                    item.status !== EnumReceiptStatus.Processing &&
                                    item.status !== EnumReceiptStatus.Converting
                                ) {
                                    changeFlag(item, !item.flag);
                                }
                            };

                            const openPreviewHandle = () => {
                                if (
                                    item.status !== EnumReceiptStatus.Processing &&
                                    item.status !== EnumReceiptStatus.Converting
                                ) {
                                    openFullPicture(item);
                                }
                            };

                            const itemStatus = () => {
                                switch (item.status) {
                                    case 'archive':
                                        return 'Archive';
                                    case EnumReceiptStatus.Converting:
                                        return 'Converting';
                                    case EnumReceiptStatus.Processing:
                                        return 'In Processing';
                                    case EnumReceiptStatus.Ready:
                                        return 'Ready';
                                    case EnumReceiptStatus.Review:
                                        return 'To Review';
                                    case EnumReceiptStatus.Error:
                                        return 'Error';
                                    case EnumReceiptStatus.Failed:
                                        return 'Failed';
                                    case EnumReceiptStatus.Saving:
                                        return 'Saving';
                                    case EnumReceiptStatus.Unassigned:
                                        return 'Unassigned';
                                    case EnumReceiptStatus.Rejected:
                                        return 'Rejected';
                                    default:
                                        return '';
                                }
                            };

                            const onEmailPress = () => {
                                getReceiptEmailInfo(item);
                                openPopUp('emailInfo')();
                            };

                            let with_cover = canBeRestarted;
                            switch (item.status) {
                                case EnumReceiptStatus.Converting:
                                case EnumReceiptStatus.Processing:
                                case EnumReceiptStatus.Saving:
                                    with_cover = true;
                            }

                            return (
                                <Tr key={index} onClick={navToEdit}>
                                    <td className="receipt_col1">
                                        <Td1Icons>
                                            <CheckBoxWrapper>
                                                <CheckBox
                                                    onClick={(event: any) => {
                                                        onSelect(item);
                                                        event.stopPropagation();
                                                    }}
                                                    active={isChecked ? true : false}
                                                />
                                            </CheckBoxWrapper>
                                            <ActionIcon
                                                alt="flag"
                                                onClick={(event: any) => {
                                                    chnageFlagHandle();
                                                    event.stopPropagation();
                                                }}
                                                src={item.flag ? flagActive : flagIcon}
                                            />
                                            {item.email_id && (
                                                <ActionIcon
                                                    alt="email"
                                                    src={emailIcon}
                                                    onClick={(event: any) => {
                                                        onEmailPress();
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            )}
                                            {item.duplicate_id && (
                                                <DuplicateInfo
                                                    receipt_id={item.receipt_id}
                                                    data={duplicateInfo}
                                                    getDuplicateInfo={() => getDuplicateInfo(item)}
                                                />
                                            )}
                                            <ActionIcon
                                                width={25}
                                                height={25}
                                                src={documentIcon}
                                                alt="doc"
                                                onMouseEnter={openPreviewHandle}
                                                onMouseLeave={closePopUp('preview')}
                                            />
                                        </Td1Icons>
                                    </td>

                                    {canBeRestarted ? (
                                        <td className="receipt_col_buttons">
                                            <ButtonGreen
                                                isValid={true}
                                                onClick={(event: any) => {
                                                    onSendAgain(item);
                                                    event.stopPropagation();
                                                }}
                                                style={notProccess ? { zIndex: 1 } : {}}
                                            >
                                                Try Again
                                            </ButtonGreen>
                                            <ButtonReject
                                                isValid={true}
                                                onClick={(event: any) => {
                                                    onDelete(item);
                                                    event.stopPropagation();
                                                }}
                                            >
                                                Delete
                                            </ButtonReject>
                                        </td>
                                    ) : (
                                        <td className="receipt_col_buttons" />
                                    )}

                                    <td className="receipt_col3">
                                        {item.user.name} {item.user.surname}
                                    </td>
                                    <td className="receipt_col4">
                                        <CompanyName>
                                            {moment(item.created_at).format('HH:MM DD/MM/YY')}
                                        </CompanyName>
                                    </td>
                                    <td className="receipt_col5">
                                        <CompanyName>{item.company_name}</CompanyName>
                                    </td>
                                    <td className="receipt_col6">
                                        {capitalizeFirstLetter(item.receipt_type)}
                                    </td>
                                    <td className="receipt_col7">
                                        <DotsText>{stakeholderVal}</DotsText>
                                    </td>
                                    <td className="receipt_col8">
                                        <Status status={item.status}>{itemStatus()}</Status>
                                    </td>
                                    {Object.keys(capturedConfig).map((k) => {
                                        // @ts-ignore
                                        if (capturedConfig[k].visible) {
                                            if (!item.content) {
                                                return (
                                                    <td className="receipt_col" key={k}>
                                                        {'-'}
                                                    </td>
                                                );
                                            }
                                            // @ts-ignore
                                            const isStakeholder = item.stakeholder[k];
                                            // @ts-ignore
                                            const isContent = item.content[k];

                                            let value = isContent || isStakeholder;

                                            if (capturedConfig[k].is_date && value) {
                                                value = moment(value).format('DD/MM/YY');
                                            }
                                            return (
                                                // @ts-ignore
                                                <td className="receipt_col" key={k}>
                                                    {value || '-'}
                                                </td>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}

                                    {with_cover && (
                                        <td
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                right: 0,
                                                top: 0,
                                                bottom: 0,
                                                backgroundColor: 'rgba(255,255,255,.5)'
                                            }}
                                        />
                                    )}
                                </Tr>
                            );
                        })
                    )}
                </tbody>
            </TableContainer>
        </TableWrapper>
    );
};

export default Table;
